<template>
  <div class="detaill">
    <div class="left">
      <div class="top">
        <div class="img_box">
          <img :src="user_info.head_pic || 'https://head.rongxinh.com/FrcV_WKrAvws6UqzsOPK_DJPz0N-'" />
          <span>{{ user_info.user_name }}</span>
        </div>
        <div>手机号:{{ vehicle_list.mobile }}</div>
        <div class="t-tops" v-if="taocanxioafei.card">
          <div>门禁卡号:{{ taocanxioafei.card.card_id }}</div>
          <div>充电卡号:{{ taocanxioafei.card.card_idto }}</div>
        </div>
      </div>
      <div class="middle">
        <span>余额情况</span>
        <div class="" style="display:flex">
          <div class="boxbox" style="margin：0 auto">
            <div>{{ newDatas.total }}</div>
            <div>储值总额</div>
          </div>
          <div class="boxbox" style="margin：0 auto">
            <div>{{ newDatas.yue }}</div>
            <div>当前余额</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span>现金消费情况</span>
        <div class="money">
          <div class="boxbox">
            <div>{{ taocanxioafei.consumption_cash }}</div>
            <div>消费总额(元)</div>
          </div>
          <div class="boxbox" style="background-color:#F49951">
            <div>{{ taocanxioafei.month_card_consumption_cash }}</div>
            <div>月卡消费</div>
          </div>
          <div class="boxbox" style="margin：0 auto">
            <div>{{ taocanxioafei.sub_card_consumption_cash }}</div>
            <div>次卡消费</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">
          <el-tabs v-model="activeName">
            <el-tab-pane :label="'场地：' + vehicle_list.address_name" name="first">
              <div class="titletitle">基本信息</div>
              <div class="infodiv">用户姓名:{{ vehicle_list.user_name }}</div>
              <div class="infodiv">手机号:{{ vehicle_list.mobile }}</div>
              <div class="infodiv">身份证号:{{ vehicle_list.id_card }}</div>
              <div class="titletitle">车辆信息</div>
              <div class="infodiv">车辆类型:{{ vehicleText(vehicle_list.vehicle_type) }}</div>
              <div class="infodiv" v-if="vehicle_list.vehicle_type != 1">车牌号:{{ vehicle_list.vehicle_number }}</div>
              <div class="infodiv">车辆照片</div>
              <div class="imgbox">
                <img
                  @click="downloadCodeImg(item)"
                  :src="item"
                  alt
                  v-for="item in vehicle_list.vehicle_number_image_path"
                  :key="item"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <el-tab-pane label="储值记录" name="charge">
          <!-- 表格 -->
          <el-table :data="order_card_list" border style="width: 100%; max-height: 65vh; overflow-y: scroll">
            <el-table-column prop="user_name" label="用户昵称"></el-table-column>
            <el-table-column prop="user_id" label="用户id"></el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
            <el-table-column prop="price" label="付款金额"></el-table-column>
            <el-table-column prop="createtime" label="时间"></el-table-column>
            <el-table-column label="状态">
              <template #default="scope">
                {{ scope.row.pay_status == 1 ? "已支付" : "已退款" }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                  :disabled="scope.row.pay_status != 1"
                  type="warning"
                  size="small"
                  @click.stop="tks(scope.$index, order_card_list)"
                  >退款
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="充值记录" name="second">
          <el-table
            :data="order_card_list"
            border
            style="width: 100%;margin-top:30px;max-height:70vh;overflow-Y:scroll"
          >
            <el-table-column prop="order_sn" label="订单编号"></el-table-column>
            <el-table-column prop="package_name" label="套餐名称"></el-table-column>
            <el-table-column prop="package_name" label="套餐类型">
              <template #default="scope">
                <span>{{ scope.row.package_type == 1 ? "月卡" : "次卡" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="场地名称" prop="address_name"></el-table-column>
            <el-table-column label="付款时间" prop="order.pay_time"></el-table-column>
            <el-table-column label="开始时间">
              <template #default="scope">{{ scope.row.start_time ? scope.row.start_time : "/" }}</template>
            </el-table-column>
            <el-table-column label="支付状态">
              <template #default="scope">{{
                scope.row.pay_status == "1" ? "已支付" : scope.row.pay_status == "2" ? "已过期" : "已退款"
              }}</template>
            </el-table-column>

            <el-table-column label="结束时间" prop="">
              <template #default="scope">{{ scope.row.end_time ? scope.row.end_time : "/" }}</template>
            </el-table-column>
            <el-table-column label="停车分钟数" prop="parking_time">
              <template #default="scope">
                {{ Math.ceil(scope.row.parking_time / 60) }}
              </template>
            </el-table-column>
            <el-table-column label="停车次数" prop="parking_number"></el-table-column>
            <el-table-column label="充电次数" prop="charge_number"></el-table-column>
            <el-table-column label="充电分钟数" prop="charge_time">
              <template #default="scope">
                {{ Math.ceil(scope.row.charge_time / 60) }}
              </template>
            </el-table-column>
            <el-table-column prop="order.order_amount" label="支付金额"></el-table-column>
            <el-table-column prop="order.rjf_points" label="支付余额"></el-table-column>
            <el-table-column prop="order.rjf_points" label="操作">
              <template #default="scope">
                <el-button
                  v-if="scope.row.pay_status == '1'"
                  @click.stop="refundCheck(scope.row)"
                  type="warning"
                  size="small"
                >
                  退款
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="充电记录" name="third">
          <el-table
            :data="order_card_list"
            border
            style="width: 100%;margin-top:30px;max-height:70vh;overflow-Y:scroll"
          >
            <el-table-column prop="address.address_name" label="场地" width="150px"></el-table-column>
            <el-table-column prop="device.device_info" label="设备"></el-table-column>
            <el-table-column label="设备端口" prop="device_port.port_name"></el-table-column>
            <el-table-column label="充电时长(分钟)" prop="set_charge_time"></el-table-column>
            <el-table-column label="剩余时长(分钟)" prop="surplus_charge_time">
              <template #default="scope">
                <span>{{ (scope.row.surplus_charge_time / 60).toFixed(0) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始时间" prop="created_at"></el-table-column>
            <el-table-column label="状态" prop="status">
              <template #default="scope">
                <span>{{ scope.row.status == 1 ? "开启" : scope.row.status == 2 ? "开启失败" : "充电结束" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="device_number" label="设备型号"></el-table-column>
            <el-table-column prop="unlock_msg" label="备注" width="250px"></el-table-column>
            <el-table-column prop="unlock_msg" label="操作">
              <template #default="scope">
                <view v-if="scope.row.shou == 2 && scope.row.is_tk == 0 && parseFloat(scope.row.price) > 0">
                  <el-button type="warning" size="small" @click.stop="tks2(scope)">退款 </el-button>
                </view>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="开门记录" name="fourth">
          <el-table
            :data="order_card_list"
            border
            style="width: 100%;margin-top:30px;max-height:70vh;overflow-Y:scroll"
          >
            <el-table-column prop="address.address_name" label="场地" width="300px"></el-table-column>
            <el-table-column label="场地门禁" prop="address_access.access_name"></el-table-column>
            <el-table-column label="是否打开">
              <template #default="scope">
                <span>{{ scope.row.is_open == 1 ? "打开" : "关闭" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开门时间" prop="created_at"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="block">
        <el-pagination
          v-if="activeName != 'first'"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 15, 20, 25]"
          :total="totalpage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 退款弹窗 -->
    <el-dialog v-model="centerDialogVisible" width="25%" center>
      <div
        style="
          text-align: center;
          width: 100%;
          font-weight: 500;
          font-size: 18px;
        "
      >
        是否确定退款
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="tuiKuanClick()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 退款弹窗2 -->
    <el-dialog v-model="centerDialogVisible2" width="25%" center>
      <div
        style="
          text-align: center;
          width: 100%;
          font-weight: 500;
          font-size: 18px;
        "
      >
        <div style="margin-bottom:20px;">退款</div>
        <div style="margin-bottom:20px;font-size: 16px">退款金额不能大于实际支付金额（{{ showpay }}元）</div>
        <div style="margin-bottom:20px;font-size: 12px;line-height: 24px;">
          提示：单笔订单只能退款一次，无论是不是全额退款， 且用户余额必须大于等于当前退款金额
        </div>
        <el-form :model="{}">
          <el-form-item label="请输入退款金额" :label-width="formLabelWidth">
            <el-input v-model="price"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="tkdd()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 套餐修改弹窗 -->
    <el-dialog title="编辑套餐" v-model="showEditPackage" width="600px">
      <el-form :model="form">
        <el-form-item label="卡类型" :label-width="formLabelWidth">
          <el-select v-model="form.package_type" placeholder="请选择">
            <el-option label="月卡" :value="1"></el-option>
            <el-option label="次卡" :value="2"></el-option>
            <el-option label="特殊套餐" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态" :label-width="formLabelWidth">
          <el-select v-model="form.pay_status" placeholder="请选择">
            <el-option label="已支付" :value="1"></el-option>
            <el-option label="已过期" :value="2"></el-option>
            <el-option label="已退款" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电分钟数" :label-width="formLabelWidth">
          <el-input v-model="charge_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="充电次数" :label-width="formLabelWidth">
          <el-input v-model="form.charge_number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="停车分钟数" :label-width="formLabelWidth">
          <el-input v-model="parking_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="停车次数" :label-width="formLabelWidth">
          <el-input v-model="form.parking_number"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="form.start_time"
            type="datetime"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="form.end_time"
            type="datetime"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              showEditPackage = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="confEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- </div> -->
</template>
<script>
import {
  vehicleFirst,
  dwTk,
  vehicleList,
  userOrderCard,
  chargeRecords,
  openTheDoorRecord,
  orderRefund,
  packageList2,
  editPackage,
  addressList1,
  dwChargin,
  dwRefund,
} from "../../request/api";
import { ElMessage } from "element-plus";
export default {
  watch: {
    page() {
      this.handleClick();
    },
    pagepage() {
      this.handleClick();
    },
    "form.start_time"(newData, oldData) {
      if (oldData !== undefined) {
        console.log("修改开始时间", newData, oldData);
        this.excangtime();
      }
    },
    "form.end_time"(newData, oldData) {
      if (oldData !== undefined) {
        console.log("修改结束时间", newData, oldData);
        this.excangtime();
      }
    },
  },
  data() {
    return {
      user_id: "",
      iduser: "",
      taocanxioafei: [],
      order_card_list: [],
      activeName: "first",
      user_info: {},
      vehicle_list: "",
      totalpage: 0,
      page: 1,
      pagepage: 10,

      centerDialogVisible: false,
      centerDialogVisible2: false,
      price: null,
      showpay: null,

      order_sn: null,
      showEditPackage: false,
      formLabelWidth: "120px",
      form: {},

      newDatas: {},
    };
  },
  created() {
    (this.user_id = this.$route.query.id), this.$store.commit("pagination");
    vehicleFirst({ user_id: this.$route.query.id, type: 1 }).then((res) => {
      this.taocanxioafei = res.list;
      this.user_info = res.list.user_info || {};
      this.vehicle_list = res.list.vehicle_list;
      this.iduser = res.list.vehicle_list.user_id;
      this.newDatas.total = res.list.deordersum;
      this.newDatas.yue = res.list.userif?.de;
    });
  },
  computed: {
    parking_time: {
      get() {
        return this.form.parking_time / 60;
      },
      set(data) {
        this.form.parking_time = data * 60;
      },
    },
    charge_time: {
      get() {
        return this.form.charge_time / 60;
      },
      set(data) {
        this.form.charge_time = data * 60;
      },
    },
  },
  methods: {
    tks(index, rows) {
      this.order_sn = rows[index].order_on;
      this.price = null;
      this.showpay = rows[index].price;
      this.centerDialogVisible2 = true;
    },
    tks2(rows) {
      console.log(rows);
      const data = rows.row;
      dwTk({
        id: data.id,
      }).then((res) => {
        console.log("退款接口", res);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "退款成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg || res.code,
          });
        }
        this.handleClick();
      });
    },
    tkdd() {
      const price = parseFloat(this.price);
      console.log(price);
      if (!price || price <= 0) {
        this.$message({
          type: "error",
          message: "请填写退款金额",
        });
        return;
      }
      if (price > this.showpay) {
        this.$message({
          type: "error",
          message: "退款金额不能大于实际支付金额",
        });
        return;
      }
      dwRefund({ order_on: this.order_sn, t_price: this.price }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "退款成功!",
          });
          this.handleClick();
          this.centerDialogVisible2 = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.centerDialogVisible2 = false;
          this.handleClick();
        }
      });
    },
    excangtime() {
      this.form.parking_time = Math.ceil((new Date(this.form.end_time) - new Date(this.form.start_time)) / 1000);
    },
    vehicleText(type) {
      switch (type) {
        case 1:
          return "自行车";
        case 2:
          return "电瓶车";
        case 3:
          return "三轮车";
        case 4:
          return "滑板车";
        case 5:
          return "摩托车";
        default:
          return type;
      }
    },
    // 编辑套餐
    editPackage(row) {
      console.log("编辑套餐内容", JSON.stringify(row));
      this.showEditPackage = true;
      this.form = JSON.parse(JSON.stringify(row));
    },
    confEdit() {
      console.log("确定修改套餐", this.form);
      editPackage({
        charge_time: this.form.charge_time,
        charge_number: this.form.charge_number,
        parking_time: this.form.parking_time,
        parking_number: this.form.parking_number,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        id: this.form.id,
        package_type: this.form.package_type,
        pay_status: this.form.pay_status,
      }).then((res) => {
        console.log("修改套餐", res);
        if (res.code == 200) {
          ElMessage.success("修改成功");
          this.showEditPackage = false;
          this.handleClick();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    // 退款
    refundCheck(row) {
      this.order_sn = row.order_sn;
      this.centerDialogVisible = true;
    },
    tuiKuanClick() {
      console.log("执行退款", this.order_sn);
      orderRefund({ order_sn: this.order_sn }).then((res) => {
        if (res.code == 200) {
          this.centerDialogVisible = false;
          ElMessage.success("退款成功");
          this.handleClick();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    handleClick() {
      let val = this.activeName;
      if (val == "second") {
        userOrderCard({
          user_id: this.iduser,
          page: this.page,
          limit: this.pagepage,
        }).then((res) => {
          this.totalpage = res.total;
          this.order_card_list = res.list;
          this.order_card_list.pay_time = res.list;
        });
      } else if (val == "third") {
        chargeRecords({
          user_id: this.iduser,
          page: this.page,
          limit: this.pagepage,
        }).then((res) => {
          this.totalpage = res.total;
          this.order_card_list = res.list;
        });
      } else if (val == "fourth") {
        openTheDoorRecord({
          user_id: this.iduser,
          page: this.page,
          limit: this.pagepage,
        }).then((res) => {
          this.totalpage = res.total;
          this.order_card_list = res.list;
        });
      } else if (val == "taocan") {
        packageList2({
          user_id: this.iduser,
          s: (this.page - 1) * this.pagepage,
          e: this.pagepage,
        }).then((res) => {
          console.log("套餐列表", res);
          this.totalpage = this.pageCount(res.list.count, this.pagepage);
          this.order_card_list = res.list.list;
        });
      } else if (val == "charge") {
        console.log("储值记录更新");
        dwChargin({
          s: (this.page - 1) * this.pagepage,
          e: this.pagepage,
          text: this.user_info.mobile,
        }).then((res) => {
          this.totalpage = this.pageCount(res.list.count, this.pagepage);
          this.order_card_list = res.list.list;
        });
      }
    },
    pageCount(totalnum, limit) {
      return totalnum > 0
        ? totalnum < limit
          ? 1
          : totalnum % limit
          ? parseInt(totalnum / limit) + 1
          : totalnum / limit
        : 0;
    },
    // 下载图片
    downloadCodeImg(src) {
      var a = document.createElement("a");
      a.target = "view_frame";
      a.download = name || "pic";
      // 设置图片地址
      a.href = src;
      a.click();
    },
    handleSizeChange(val) {
      this.pagepage = val;
    },
    handleCurrentChange(val) {
      this.page = val;
    },
  },
};
</script>

<style lang="less" scoped>
.t-tops {
  margin-top: 10px;

  div {
    margin-bottom: 10px;
  }
}

.detaill {
  .block {
    position: fixed;
    bottom: 30px;
    left: 40%;
  }

  .right {
    width: 73%;
    height: 800px;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;

    .infodiv {
      color: #333;
      font-size: 15px;
      margin-top: 18px;
    }

    .imgbox {
      margin-top: 18px;

      img {
        width: 200px;
        height: 130px;
        margin-right: 30px;
      }
    }
  }

  .boxbox {
    width: 150px;
    height: 80px;
    color: #fff;
    background-color: #1dbff0;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;

    div:nth-of-type(1) {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  width: 100%;
  background-color: #eeeeee;
  display: flex;
  height: 800px;
  justify-content: space-between;

  .left {
    width: 25%;

    .top {
      text-align: center;
      background-color: #fff;
      height: 200px;
      border-radius: 8px;

      .img_box {
        width: 80px;
        margin: 0 auto;
        height: 120px;
        text-align: center;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }

    .middle {
      text-align: center;
      background-color: #fff;
      height: 200px;
      border-radius: 8px;
      margin-top: 30px;

      span {
        line-height: 80px;
      }
    }

    .bottom {
      text-align: center;
      background-color: #fff;
      // height: 250px;
      border-radius: 8px;
      margin-top: 30px;

      span {
        line-height: 80px;
      }

      .money {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .boxbox {
        margin-bottom: 20px;
      }
    }
  }

  .titletitle {
    background-color: #dfe7fd;
    color: #0939ca;
    font-size: 15px;
    line-height: 30px;
    position: relative;
    margin: 30px 0;
    padding-left: 30px;
  }

  .titletitle::after {
    content: "";
    width: 4px;
    height: 12px;
    background-color: #fff;
    position: absolute;
    left: 10px;
    top: 8px;
  }
}
</style>
